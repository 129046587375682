<template>
  <a-row>
    <a-row class="content-title" type="flex">
      <h2 class="flex-1">
        {{ $route.meta.title[$i18n.locale] }}
      </h2>
    </a-row>
    <a-table
      :columns="columns"
      :data-source="list"
      :loading="loading"
      :pagination="false"
      :row-key="(record) => record.id"
    >
      <template slot="name" slot-scope="item">
        <router-link
          :to="{
            name: 'catalog-detail',
            params: {
              category: item.category,
            },
          }"
          class="td-post-item"
          tag="span"
        >
          {{ item.name[$i18n.locale] }}
        </router-link>
      </template>
    </a-table>
  </a-row>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      columns: [
        {
          title: this.$t("TagsColTitle"),
          key: "name",
          scopedSlots: { customRender: "name" },
          width: "90%",
        },
      ],
      list: [
        {
          name: {
            uz: "Viloyatlar",
            oz: "Вилоятлар",
            ru: "Провинции",
            en: "Провинции",
          },
          category: "region",
        },
        {
          name: {
            uz: "Tumanlar",
            oz: "Туманлар",
            ru: "Районы",
            en: "Районы",
          },
          category: "district",
        },
      ],
    };
  },
};
</script>
